
.form-outer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.register-form-container {
  background: #fff;
  border: 1px solid #e0e0e0;
  width: 420px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
}

.login-form-container {
  background: #fff;
  border: 1px solid #e0e0e0;
  width: 420px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
}

.contact-sales-form-container {
  padding: 0px 115px 90px 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.contact-sales-form-container > div > .primary-title {
  margin-bottom: 15px;
}

.contact-sales-text-container {
  margin-right: 100px;
  width: 29%;
}

.form-header {
  font-family: var(--secondary-font);
  font-size: 30px;
  color: var(--dark-color);
  font-weight: bold;
  text-align: center;
  padding: 10px 0px;
}

.form-sub-header {
  font-family: var(--primary-font);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #a09fa8;
  padding: 0 20px;
}

.forgot-password-link {
  font-family: var(--primary-font);
  font-size: 14px;
  color: #a09fa8;
  display: inline-block;
  margin-top: 8px;
}

.forgot-password-link:focus {
  text-decoration: none;
}

.forgot-password-link:hover {
  color: #86858b;
}

.form-btn-container > :first-child {
  margin-bottom: 20px;
}

.name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.name-container > .ant-form-item {
  width: 48%;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox-checked::after {
  border-color: var(--primary-color) !important;
}

.ant-checkbox + span {
  color: #656274;
  font-weight: 400;
  font-family: var(--primary-font);
  font-size: 16px;
  line-height: 24px;
}

/* RESPONSIVE */
@media screen and (max-width: 1500px) {
  .contact-sales-text-container {
    width: 40%;
  }
}

@media screen and (max-width: 1200px) {
  .contact-sales-text-container {
    width: 50%;
    margin-right: 50px;
  }
}

@media screen and (max-width: 1000px) {
  .contact-sales-form-container {
    flex-direction: column;
  }

  .contact-sales-text-container {
    width: 420px;
    margin: 80px 0px 20px 0px;
  }

  .contact-sales-form-container > .form-outer-container {
    margin-top: 0px;
  }
}

@media screen and (max-width: 550px) {
  .form-outer-container {
    padding: 0px 20px 100px;
  }
}

@media screen and (max-width: 520px) {
  .contact-sales-form-container {
    padding: 0px 20px 20px;
  }

  .contact-sales-text-container > .primary-title {
    font-size: 30px;
  }
}

@media screen and (max-width: 500px) {
  .contact-sales-form-container > .form-outer-container {
    padding: 0;
  }
  .form-outer-container {
    width: 100%;
  }

  .contact-sales-form-container
    > .form-outer-container
    > .register-form-container {
    width: 100%;
  }

  .contact-sales-text-container {
    width: 100%;
  }

  .name-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }

  .name-container > .ant-form-item {
    width: 100%;
  }
}

@media screen and (max-width: 400px) {
  .register-form-container,
  .login-form-container {
    padding: 30px 15px;
  }
}

.back-button {
  position: absolute;
  left: 100px;
}

@media screen and (max-width: 800px) {
  .back-button {
    left: 20px;
  }
}

.dashboard-container {
  padding: 100px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin: auto;
  width: 80%;
  box-sizing: border-box;
}

.dashboard-contents {
  max-width: 950px;
}

.dashboard-profile-text-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 25px;
}

.dashboard-text-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 50px;
}

.dashboard-user-name-text {
  font-family: var(--primary-font);
  color: var(--grey-color);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 5px;
}

.avatar-container > * {
  width: 60px !important;
  height: 60px !important;
  font-size: 24px !important;
}

.dashboard-title-text {
  font-size: 20px;
  font-family: var(--primary-font);
  font-weight: 500;
  color: var(--dark-color);
}

.empty-text {
  font-size: 14px;
  font-family: var(--primary-font);
  font-weight: 400;
  color: var(--grey-color);
  margin: 10px 0px 38px;
  line-height: normal;
}

.video-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 35px;
  max-width: 100%;
}

.video-cards-container {
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.video-card {
  width: 200px;
}

@media screen and (max-width: 1400px) {
  .video-cards-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .dashboard-container {
    width: 100%;
    padding: 100px 20px;
  }

  .avatar-container svg {
    width: 45px;
    height: 45px;
  }
}

@media screen and (max-width: 700px) {
  .video-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-container {
    width: 80%;
    padding: 100px 20px;
  }
}

@media screen and (max-width: 550px) {
  .dashboard-profile-text-container > .primary-title {
    font-size: 24px;
  }
}

@media screen and (max-width: 480px) {
  .dashboard-container {
    width: 100%;
  }

  .dashboard-contents {
    width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .dashboard-container {
    padding: 100px 10px;
  }

  .video-cards-container {
    grid-gap: 15px;
  }
}

@media screen and (max-width: 350px) {
  .video-cards-container {
    grid-template-columns: 1fr;
  }
  .dashboard-container {
    padding: 100px 30px;
  }
}

.video-card-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e5e2;
  width: 225px;
  border-radius: 8px;
}

.video-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.video-description {
  color: var(--dark-color);
  font-family: var(--secondary-font);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 0px 10px 10px;
}

.klip-video-img {
  height: auto;
}

.uploaded-time-text {
  color: #a09fa8;
  font-size: 12px;
  line-height: 20px;
  font-family: var(--secondary-font);
  font-weight: 400;
}

.video-card-header,
.uploaded-time-text {
  padding: 10px;
}

.video-card-header
  > .ant-space
  > .ant-space-item
  > .ant-dropdown-button
  > .ant-btn-icon-only {
  border: none;
}

@media screen and (max-width: 580px) {
  .video-card-container {
    width: 180px;
  }
}

@media screen and (max-width: 390px) {
  .video-card-container {
    width: 170px;
  }
}

@media screen and (max-width: 330px) {
  .video-card-container {
    width: 100%;
  }
}

/* LANDING PAGE SECTION */
.landing-main-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 100px 150px;
}

.landing-section-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 60px 100px;
}

.landing-section-container:nth-of-type(odd) {
  flex-direction: row-reverse;
}

.landing-text-container {
  width: 35%;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 80px;
}

.landing-title-text {
  color: var(--dark-color);
  font-family: var(--primary-font);
  font-size: 24px;
  align-self: flex-start;
  line-height: 45px;
}

.landing-sub-text {
  color: #656274;
  font-family: var(--primary-font);
  font-size: 20px;
  line-height: 30px;
  text-align: left;
}

.landing-section-img {
  /* height: 80%; */
  height: auto;
}

.landing-main-img {
  height: auto;
  width: 50vw;
}

/* LANDING PAGE */
.landing-referred-user-text-container {
  width: 40%;
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 60px;
}

.referred-user-title {
  font-size: 46px;
  line-height: 40px;
  margin-bottom: 25px;
  font-family: var(--secondary-font);
  color: var(--dark-color);
  font-weight: 500;
}

.referred-user-subtitle {
  font-size: 24px;
  line-height: 25px;
  margin-bottom: 25px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-weight: 400;
}

.landing-user-code {
  font-size: 24px;
  margin-bottom: 25px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-weight: 500;
}

.landing-primary-title {
  font-size: 26px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  line-height: 32px;
  text-align: center;
}

.landing-primary-title:nth-of-type(1) {
  margin-bottom: 45px;
}

.landing-img {
  display: block;
  width: 90%;
  padding: 0px 100px;
  margin: 0px auto 85px;
}

.landing-footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 100px 0px 0px 0px;
  padding-bottom: 115px;
}

.landing-footer-content,
.landing-secondary-title {
  margin-bottom: 30px;
}

.landing-secondary-title {
  font-size: 26px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-weight: 500;
  line-height: 30px;
}

/* RESPONSIVE */
@media screen and (max-width: 1520px) {
  .landing-section-container {
    padding: 80px;
  }

  .landing-referred-user-text-container {
    width: 70%;
    margin: 0px 50px 0px 0px;
  }

  .referred-user-title {
    font-size: 36px;
  }

  .referred-user-subtitle,
  .landing-user-code {
    font-size: 20px;
  }

  .landing-secondary-title {
    font-size: 24px;
  }

  .landing-img {
    width: 100%;
    padding: 0 80px;
  }

  .landing-section-img {
    /* height: 400px; */
    width: 40%;
  }

  .landing-text-container {
    width: 55%;
  }
}

@media screen and (max-width: 1200px) {
  .landing-main-container {
    padding: 0px 0px 100px 70px;
  }

  .landing-section-container {
    padding: 60px 40px;
  }

  .landing-primary-title,
  .landing-secondary-title {
    font-size: 24px;
  }

  .landing-img {
    width: 100%;
    /* height: 500px; */
    padding: 0 40px;
  }

  .landing-text-container {
    margin: 0 40px;
  }
}

@media screen and (max-width: 1000px) {
  .referred-user-title {
    font-size: 28px;
  }

  .landing-primary-title,
  .landing-secondary-title {
    font-size: 22px;
  }

  .referred-user-subtitle,
  .landing-title-text,
  .landing-sub-text,
  .landing-user-code {
    font-size: 18px;
  }
}

@media screen and (max-width: 950px) {
  .landing-main-container {
    padding: 0px 0px 100px 40px;
  }

  .landing-img {
    padding: 0 20px;
  }

  .landing-section-container {
    padding: 60px 20px;
  }

  .landing-section-img {
    /* height: 350px; */
    height: 45%;
  }
}

/* @media screen and (max-width: 835px) {} */

@media screen and (max-width: 760px) {
  .landing-main-container,
  .landing-section-container,
  .landing-section-container:nth-of-type(odd) {
    flex-direction: column;
    padding: 0;
  }

  .landing-referred-user-text-container {
    margin: 0;
  }

  .landing-referred-user-text-container,
  .landing-text-container {
    width: 100%;
    padding: 40px 20px;
  }

  .landing-main-img {
    width: 100%;
    /* height: 550px; */
  }

  .landing-section-img {
    width: 100%;
    /* height: 500px; */
  }

  .landing-img {
    padding: 0;
    margin: 0;
  }

  .landing-primary-title:nth-of-type(1) {
    margin-bottom: 0px;
  }

  .landing-primary-title:nth-of-type(2) {
    padding-bottom: 0px;
  }

  .landing-primary-title {
    padding: 40px 15px;
    margin: 0;
    font-size: 22px;
  }

  .promo-buttons-container {
    width: 100%;
    flex-direction: column;
  }

  .promo-buttons-container .ant-btn {
    width: 100%;
  }

  .promo-buttons-container > .ant-btn.ant-btn-large {
    margin-right: 0;
  }

  .promo-buttons-container > .ant-btn:not(:first-of-type),
  .promo-buttons-container > .ant-btn:not(:nth-of-type(2)) {
    margin: 0px 0px 10px 0px !important;
  }

  .landing-footer-content {
    padding: 40px 15px;
    margin: 0;
  }
}

@media screen and (max-width: 400px) {
  .landing-img {
    /* height: 250px; */
  }

  .landing-section-img {
    /* height: 400px; */
  }
}

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 100px;
}

.navbar-container > svg {
  height: 50px;
}

#link-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: var(--primary-color);
  font-family: var(--primary-font);
  font-size: 16px;
  grid-gap: 5px;
  gap: 5px;
}

#link-btn > p {
  margin: 0;
}

@media screen and (max-width: 800px) {
  .navbar-container {
    padding: 20px 15px;
  }
}

.site-layout-background {
  padding: 24px;
  min-height: 360px;
}

@media only screen and (max-width: 499px) {
  .site-layout-background {
    padding: 4px;
  }
}

.content-container {
  background: #fff;
  flex-direction: column;
  margin-left: 250px;
  max-width: 100%;
}

.ant-menu {
  display: flex;
  flex-direction: column;
  min-height: 85%;
}

.logo-container {
  padding: 20px;
}

.nav-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-header-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 125px;
}

.nav-header-items > *:hover {
  cursor: pointer;
}

.ant-layout-sider,
.ant-layout-sider-dark {
  background: #f6f6f6 !important;
  z-index: 100 !important;
}

.ant-layout-sider-children {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.ant-layout-sider-children::-webkit-scrollbar {
  display: none;
}

.ant-layout-header {
  background: #fff !important;
}

.ant-menu {
  background: #f6f6f6 !important;
}

.ant-menu-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-menu-item > svg {
  margin-right: 10px;
}

.ant-menu-item::after {
  border-right-color: #633eff !important;
}

.ant-menu-item-selected {
  background: #eae5ff !important;
  color: #633eff !important;
}

.ant-menu-item-active {
  color: #633eff !important;
}

.ant-menu > .ant-menu-item:hover,
.ant-menu > .ant-menu-submenu:hover,
.ant-menu > .ant-menu-item-active:active,
.ant-menu-item:focus,
.ant-menu-item:active,
.ant-menu > .ant-menu-submenu-active,
.ant-menu > .ant-menu-item-open,
.ant-menu > .ant-menu-submenu-open,
.ant-menu > .ant-menu-item-selected,
.ant-menu > .ant-menu-submenu-selected {
  background: #eae5ff !important;
  color: #633eff !important;
}

.menu-header {
  color: var(--dark-color);
  font-size: 16px;
  font-weight: 500;
  padding: 8px 0px 6px 20px;
  margin-top: 30px;
}

.drop-down {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 8px 16px;
  width: 90%;
  margin: 10px auto;
}

.drop-down > svg:hover {
  cursor: pointer;
}

.drop-down-text {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.drop-down-title {
  color: var(--dark-color);
  font-weight: bold;
}

.drop-down-sub-title {
  color: #a09fa8;
}

.drop-down-text p {
  margin: 5px 0;
}

/* RESPONSIVE */
@media screen and (max-width: 991px) {
  .content-container {
    margin-left: 0px;
  }

  .nav-header {
    padding: 0 25px;
  }

  .nav-header-items {
    width: 100px;
  }

  .nav-header-items > div {
    width: 26px !important;
    height: 26px !important;
    font-size: 14px !important;
  }
}

.settings-container {
  display: flex;
  align-items: flex-start;
  height: auto;
  flex-direction: column;
  padding: 45px 85px 0px;
}

/* STATUS CARD */
.settings-card-title {
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;
}

.settings-status-container,
.badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-container > :first-child {
  margin: 0 10px 0 10px;
}

.status-card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  padding: 40px 30px 80px 30px;
}

.settings-status-info-text {
  font-family: var(--primary-font);
  color: var(--grey-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
}

.settings-status-price-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.settings-status-seats-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  min-height: 60px;
}

/* PLAN CARD */
.plan-cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 300px;
  width: 100%;
  padding: 0px 0px 50px;
}

.plan-card-title {
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin: 0;
}

.plan-card-info-text {
  font-family: var(--primary-font);
  color: var(--grey-color);
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
}

.plan-card-price-container {
  margin: 80px 0;
  min-height: 70px;
}

.plan-card-price {
  font-size: 38px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  line-height: 46px;
  font-weight: 500;
  margin-bottom: 5px;
}

.plan-card-spacer {
  margin-right: 40px;
}

.settings-features-text {
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.settings-workspace-list {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 160px;
}

.settings-feature-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
}

.settings-feature-item-text {
  margin: 0 0 0 10px;
  line-height: 20px;
}

/* MODAL */
.modal-cards-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.modal-cards-container > .modal-card-container:nth-child(1) {
  width: 55%;
  margin-right: 20px;
}

.modal-cards-container > .modal-card-container:nth-child(2) {
  width: 45%;
}

.modal-card-container {
  height: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px 30px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.confirmation-modal-container {
  padding: 100px 110px 100px 110px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-show-details-text {
  font-size: 16px;
  font-family: var(--primary-font);
  color: var(--light-grey-color);
  line-height: 24px;
  font-weight: 400;
  margin: 0 6px 0 0;
}

.modal-show-details-text:hover {
  cursor: pointer;
}

.modal-cost-list {
  width: 100%;
  margin-top: 60px;
}

.modal-cost-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.modal-cost-item:last-of-type {
  border-top: 1px solid #e0e0e0;
  padding-top: 30px;
}

.modal-cost-item-text {
  font-size: 16px;
  font-family: var(--primary-font);
  color: var(--grey-color);
  line-height: 24px;
  font-weight: 700;
}

.modal-cost-item-total {
  font-size: 22px;
  font-family: var(--primary-font);
  color: var(--grey-color);
  line-height: 26px;
  font-weight: 600;
}

.success-modal-container {
  padding: 250px 700px 250px 115px;
}

.success-modal-message {
  font-family: var(--primary-font);
  color: var(--grey-color);
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin: 40px 0 0 0;
}

#success-btn {
  margin-top: 25px;
}

/* RESPONSIVE */
@media screen and (max-width: 1500px) {
  .confirmation-modal-container {
    padding: 50px 50px 50px 50px;
  }

  .success-modal-container {
    padding: 150px 400px 150px 115px;
  }
}

@media screen and (max-width: 1270px) {
  .confirmation-modal-container {
    padding: 40px;
  }

  .success-modal-container {
    padding: 150px 300px 150px 115px;
  }

  .plan-card-spacer {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1239px) {
  .settings-container {
    padding: 45px 35px 0px;
  }
}

@media screen and (max-width: 900px) {
  .success-modal-container {
    padding: 150px 100px 150px 80px;
  }

  .status-card-top,
  .settings-status-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .status-card-top > .settings-card-title {
    margin-bottom: 8px;
  }

  .badge-container {
    margin-top: 8px;
  }

  .badge-container > :first-child {
    margin: 0 10px 0 0;
  }
}

@media screen and (max-width: 780px) {
  .settings-container {
    padding: 45px 85px 0px;
  }

  .plan-cards-container {
    flex-direction: column;
  }

  .plan-cards-container {
    margin: 20px 0px 0px;
  }

  .plan-cards-container > :nth-child(1),
  .plan-cards-container > :nth-child(3),
  .plan-cards-container > :nth-child(5) {
    /* Using !important tags here to overwrite the existing inline styles */
    width: 100% !important;
    padding: 30px 30px 50px !important;
  }

  .plan-cards-container > :nth-child(1),
  .plan-cards-container > :nth-child(3) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 770px) {
  .confirmation-modal-container {
    padding: 20px 0px;
  }

  .modal-cards-container {
    flex-direction: column;
    margin: 30px 0px 60px;
  }

  .modal-card-container {
    padding: 30px 20px;
  }

  .modal-card-container > .settings-card-title {
    font-size: 20px;
  }

  .modal-cards-container > .modal-card-container:nth-child(1) {
    width: 100%;
    margin: 0px 0px 20px 0px;
    max-height: 270px;
  }

  .modal-cards-container > .modal-card-container:nth-child(2) {
    width: 100%;
    max-height: 270px;
  }

  .confirmation-modal-container > .primary-title {
    font-size: 24px;
  }

  .plan-card-price-container {
    margin: 25px 0px;
  }

  .modal-cost-list {
    margin-top: 20px;
  }

  .modal-cost-item {
    padding-bottom: 5px;
  }

  .modal-cost-item:last-of-type {
    border-top: 1px solid #e0e0e0;
    padding-top: 15px;
  }

  .modal-cost-item-text {
    font-weight: 500;
  }

  #purchase-plan-btn {
    width: 100%;
  }

  .success-modal-container {
    padding: 120px 80px 120px 60px;
  }
}

@media screen and (max-width: 600px) {
  .success-modal-container {
    padding: 80px 15px;
  }

  #success-btn {
    width: 100%;
    margin-top: 50px;
  }

  .success-modal-message {
    font-size: 16px;
  }

  .settings-card-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 525px) {
  .settings-container {
    padding: 45px 20px 0px;
  }
}

@media screen and (max-width: 475px) {
  .settings-status-seats-container {
    justify-content: flex-start;
  }
  .settings-status-seats-container > .settings-status-info-text:last-of-type {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .badge-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .badge-container > :first-child {
    margin-bottom: 8px;
  }
}

/* Reset.less
 * Props to Eric Meyer (meyerweb.com) for his CSS reset file. We're using an adapted version here	that cuts out some of the reset HTML elements we will never need here (i.e., dfn, samp, etc).
 * ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* VARIABLES */
:root {
  --primary-color: #4225c4;
  --primary-font: "Inter", sans-serif;
  --secondary-font: "Roboto", sans-serif;
  --dark-color: #100932;
  --grey-color: #656274;
  --light-grey-color: #a09fa8;
  --antd-wave-shadow-color: var(--primary-color) !important;
}

/* TEXT STYLES */
.primary-title {
  font-size: 34px;
  font-family: "Roboto", sans-serif;
  font-family: var(--secondary-font);
  color: #100932;
  color: var(--dark-color);
  line-height: 36px;
  font-weight: 500;
  margin: 0;
}

.paragraph-text {
  font-family: "Inter", sans-serif;
  font-family: var(--primary-font);
  color: #656274;
  color: var(--grey-color);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

@media screen and (max-width: 500px) {
  .primary-title {
    font-size: 28px;
  }

  .paragraph-text {
    font-size: 16px;
  }
}

.lang-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lang-container:hover {
  cursor: pointer;
}

/* FOOTER */
.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 150px;
  color: #444445;
  justify-self: flex-end;
}

.footer-links-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.footer-links-container a {
  padding-right: 20px;
}

.footer-links-container a:hover,
.footer-text a:hover {
  color: #4225c4;
  color: var(--primary-color);
}

.footer-links-container a,
.footer-text {
  text-decoration: none;
  font-size: 16px;
  color: #444445;
}

.footer-text a {
  font-size: 16px;
  color: #444445;
  text-decoration: none;
}

@media screen and (max-width: 375px) {
  .footer-text a,
  .footer-text,
  .footer-links-container a {
    font-size: 14px;
  }
}

/* ANT OVERWRITES */
.ant-btn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: "Inter", sans-serif !important;
  font-family: var(--primary-font) !important;
  border-color: #4225c4 !important;
  border-color: var(--primary-color) !important;
  color: #4225c4 !important;
  color: var(--primary-color) !important;
  border-radius: 4px !important;
  padding: 18px !important;
}

.ant-btn-primary {
  background: #4225c4 !important;
  background: var(--primary-color) !important;
  color: #fff !important;
}

.ant-input {
  padding: 8px !important;
}

.ant-input:hover,
.ant-input:focus {
  border-color: #4225c4 !important;
  border-color: var(--primary-color) !important;
}

.ant-input:focus {
  box-shadow: 0 0 0 2px rgba(66, 37, 196, 0.2) !important;
}

.ant-form-item-label > label {
  color: #100932 !important;
  color: var(--dark-color) !important;
  font-family: "Roboto", sans-serif !important;
  font-family: var(--secondary-font) !important;
  line-height: 25px;
}

html,
body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dd,
dl,
dt,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ol,
ul {
  list-style: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted;
}
a:hover,
a:active {
  outline: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
button,
input {
  line-height: normal;
  *overflow: visible;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
@-webkit-keyframes glow {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
@keyframes glow {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

