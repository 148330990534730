/* Reset.less
 * Props to Eric Meyer (meyerweb.com) for his CSS reset file. We're using an adapted version here	that cuts out some of the reset HTML elements we will never need here (i.e., dfn, samp, etc).
 * ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* VARIABLES */
:root {
  --primary-color: #4225c4;
  --primary-font: "Inter", sans-serif;
  --secondary-font: "Roboto", sans-serif;
  --dark-color: #100932;
  --grey-color: #656274;
  --light-grey-color: #a09fa8;
  --antd-wave-shadow-color: var(--primary-color) !important;
}

/* TEXT STYLES */
.primary-title {
  font-size: 34px;
  font-family: var(--secondary-font);
  color: var(--dark-color);
  line-height: 36px;
  font-weight: 500;
  margin: 0;
}

.paragraph-text {
  font-family: var(--primary-font);
  color: var(--grey-color);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

@media screen and (max-width: 500px) {
  .primary-title {
    font-size: 28px;
  }

  .paragraph-text {
    font-size: 16px;
  }
}

.lang-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lang-container:hover {
  cursor: pointer;
}

/* FOOTER */
.footer-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 150px;
  color: #444445;
  justify-self: flex-end;
}

.footer-links-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.footer-links-container a {
  padding-right: 20px;
}

.footer-links-container a:hover,
.footer-text a:hover {
  color: var(--primary-color);
}

.footer-links-container a,
.footer-text {
  text-decoration: none;
  font-size: 16px;
  color: #444445;
}

.footer-text a {
  font-size: 16px;
  color: #444445;
  text-decoration: none;
}

@media screen and (max-width: 375px) {
  .footer-text a,
  .footer-text,
  .footer-links-container a {
    font-size: 14px;
  }
}

/* ANT OVERWRITES */
.ant-btn {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-family: var(--primary-font) !important;
  border-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
  border-radius: 4px !important;
  padding: 18px !important;
}

.ant-btn-primary {
  background: var(--primary-color) !important;
  color: #fff !important;
}

.ant-input {
  padding: 8px !important;
}

.ant-input:hover,
.ant-input:focus {
  border-color: var(--primary-color) !important;
}

.ant-input:focus {
  box-shadow: 0 0 0 2px rgba(66, 37, 196, 0.2) !important;
}

.ant-form-item-label > label {
  color: var(--dark-color) !important;
  font-family: var(--secondary-font) !important;
  line-height: 25px;
}

html,
body {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
cite,
code,
del,
dfn,
em,
img,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dd,
dl,
dt,
li,
ol,
ul,
fieldset,
form,
label,
legend,
button,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  font-style: normal;
  font-size: 100%;
  line-height: 1;
  font-family: inherit;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
ol,
ul {
  list-style: none;
}
q:before,
q:after,
blockquote:before,
blockquote:after {
  content: "";
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted;
}
a:hover,
a:active {
  outline: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
audio:not([controls]) {
  display: none;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
button,
input {
  line-height: normal;
  *overflow: visible;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto;
  vertical-align: top;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes glow {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
