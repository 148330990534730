.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 100px;
}

.navbar-container > svg {
  height: 50px;
}

#link-btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  color: var(--primary-color);
  font-family: var(--primary-font);
  font-size: 16px;
  gap: 5px;
}

#link-btn > p {
  margin: 0;
}

@media screen and (max-width: 800px) {
  .navbar-container {
    padding: 20px 15px;
  }
}
