.settings-container {
  display: flex;
  align-items: flex-start;
  height: auto;
  flex-direction: column;
  padding: 45px 85px 0px;
}

/* STATUS CARD */
.settings-card-title {
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin: 0;
}

.settings-status-container,
.badge-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.badge-container > :first-child {
  margin: 0 10px 0 10px;
}

.status-card-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  padding: 40px 30px 80px 30px;
}

.settings-status-info-text {
  font-family: var(--primary-font);
  color: var(--grey-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin: 0;
}

.settings-status-price-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.settings-status-seats-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  min-height: 60px;
}

/* PLAN CARD */
.plan-cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px 300px;
  width: 100%;
  padding: 0px 0px 50px;
}

.plan-card-title {
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin: 0;
}

.plan-card-info-text {
  font-family: var(--primary-font);
  color: var(--grey-color);
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin: 0;
}

.plan-card-price-container {
  margin: 80px 0;
  min-height: 70px;
}

.plan-card-price {
  font-size: 38px;
  font-family: var(--primary-font);
  color: var(--dark-color);
  line-height: 46px;
  font-weight: 500;
  margin-bottom: 5px;
}

.plan-card-spacer {
  margin-right: 40px;
}

.settings-features-text {
  font-family: var(--primary-font);
  color: var(--dark-color);
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.settings-workspace-list {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 160px;
}

.settings-feature-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0px;
}

.settings-feature-item-text {
  margin: 0 0 0 10px;
  line-height: 20px;
}

/* MODAL */
.modal-cards-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.modal-cards-container > .modal-card-container:nth-child(1) {
  width: 55%;
  margin-right: 20px;
}

.modal-cards-container > .modal-card-container:nth-child(2) {
  width: 45%;
}

.modal-card-container {
  height: 400px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 40px 30px;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
}

.confirmation-modal-container {
  padding: 100px 110px 100px 110px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-show-details-text {
  font-size: 16px;
  font-family: var(--primary-font);
  color: var(--light-grey-color);
  line-height: 24px;
  font-weight: 400;
  margin: 0 6px 0 0;
}

.modal-show-details-text:hover {
  cursor: pointer;
}

.modal-cost-list {
  width: 100%;
  margin-top: 60px;
}

.modal-cost-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;
}

.modal-cost-item:last-of-type {
  border-top: 1px solid #e0e0e0;
  padding-top: 30px;
}

.modal-cost-item-text {
  font-size: 16px;
  font-family: var(--primary-font);
  color: var(--grey-color);
  line-height: 24px;
  font-weight: 700;
}

.modal-cost-item-total {
  font-size: 22px;
  font-family: var(--primary-font);
  color: var(--grey-color);
  line-height: 26px;
  font-weight: 600;
}

.success-modal-container {
  padding: 250px 700px 250px 115px;
}

.success-modal-message {
  font-family: var(--primary-font);
  color: var(--grey-color);
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  margin: 40px 0 0 0;
}

#success-btn {
  margin-top: 25px;
}

/* RESPONSIVE */
@media screen and (max-width: 1500px) {
  .confirmation-modal-container {
    padding: 50px 50px 50px 50px;
  }

  .success-modal-container {
    padding: 150px 400px 150px 115px;
  }
}

@media screen and (max-width: 1270px) {
  .confirmation-modal-container {
    padding: 40px;
  }

  .success-modal-container {
    padding: 150px 300px 150px 115px;
  }

  .plan-card-spacer {
    margin-right: 20px;
  }
}

@media screen and (max-width: 1239px) {
  .settings-container {
    padding: 45px 35px 0px;
  }
}

@media screen and (max-width: 900px) {
  .success-modal-container {
    padding: 150px 100px 150px 80px;
  }

  .status-card-top,
  .settings-status-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .status-card-top > .settings-card-title {
    margin-bottom: 8px;
  }

  .badge-container {
    margin-top: 8px;
  }

  .badge-container > :first-child {
    margin: 0 10px 0 0;
  }
}

@media screen and (max-width: 780px) {
  .settings-container {
    padding: 45px 85px 0px;
  }

  .plan-cards-container {
    flex-direction: column;
  }

  .plan-cards-container {
    margin: 20px 0px 0px;
  }

  .plan-cards-container > :nth-child(1),
  .plan-cards-container > :nth-child(3),
  .plan-cards-container > :nth-child(5) {
    /* Using !important tags here to overwrite the existing inline styles */
    width: 100% !important;
    padding: 30px 30px 50px !important;
  }

  .plan-cards-container > :nth-child(1),
  .plan-cards-container > :nth-child(3) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 770px) {
  .confirmation-modal-container {
    padding: 20px 0px;
  }

  .modal-cards-container {
    flex-direction: column;
    margin: 30px 0px 60px;
  }

  .modal-card-container {
    padding: 30px 20px;
  }

  .modal-card-container > .settings-card-title {
    font-size: 20px;
  }

  .modal-cards-container > .modal-card-container:nth-child(1) {
    width: 100%;
    margin: 0px 0px 20px 0px;
    max-height: 270px;
  }

  .modal-cards-container > .modal-card-container:nth-child(2) {
    width: 100%;
    max-height: 270px;
  }

  .confirmation-modal-container > .primary-title {
    font-size: 24px;
  }

  .plan-card-price-container {
    margin: 25px 0px;
  }

  .modal-cost-list {
    margin-top: 20px;
  }

  .modal-cost-item {
    padding-bottom: 5px;
  }

  .modal-cost-item:last-of-type {
    border-top: 1px solid #e0e0e0;
    padding-top: 15px;
  }

  .modal-cost-item-text {
    font-weight: 500;
  }

  #purchase-plan-btn {
    width: 100%;
  }

  .success-modal-container {
    padding: 120px 80px 120px 60px;
  }
}

@media screen and (max-width: 600px) {
  .success-modal-container {
    padding: 80px 15px;
  }

  #success-btn {
    width: 100%;
    margin-top: 50px;
  }

  .success-modal-message {
    font-size: 16px;
  }

  .settings-card-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 525px) {
  .settings-container {
    padding: 45px 20px 0px;
  }
}

@media screen and (max-width: 475px) {
  .settings-status-seats-container {
    justify-content: flex-start;
  }
  .settings-status-seats-container > .settings-status-info-text:last-of-type {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .badge-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .badge-container > :first-child {
    margin-bottom: 8px;
  }
}
