.site-layout-background {
  padding: 24px;
  min-height: 360px;
}

@media only screen and (max-width: 499px) {
  .site-layout-background {
    padding: 4px;
  }
}
